import React from "react"
import styled from "styled-components"
import Layout from "../../layout"
import { useQuery } from "../hooks/useQuery"
import { useEffect } from "react"
import { useState } from "react"
import currency from "currency.js"
import { useHistory } from "react-router-dom"
// services
import { getPeriodValues } from "../../../services/buildings"
import { newOrder } from "../../../services/orders"
import { getUSDRate } from "../../../services/fixer"
import { getAvailableCountByBuilding } from "../../../services/tokens"
// components
import Building from "./Building"
import { useDispatch, useSelector } from "react-redux"
import Loader from "../../reports/loader"
import { setAddToCart } from "../../../state/actions"

export default function SelectFractions() {
  let query = useQuery()
  const dispatch = useDispatch()
  let history = useHistory()
  const user = useSelector((state) => state.user)
  const cart = useSelector((state) => state.cart)
  const [buildings, setBuildings] = useState([])
  const [initialPayment, setInitialPayment] = useState(null)
  const [subscription, setSubscription] = useState(null)
  const [newPrice, setNewPrice] = useState(0)
  const [showLoader, setShowLoader] = useState(false)
  const [availableTokens, setAvailableTokens] = useState({})

  const [USDRate, setUSDRate] = useState(0)

  const getExchangeReate = async (initial, currency) => {
    if (!USDRate) {
      const exchangeRate = await getUSDRate(user.token)
      setUSDRate(exchangeRate)
      if (currency) {
        setInitialPayment(initial * exchangeRate + 3)
      } else {
        setInitialPayment(initial)
      }
    }
  }

  // Get initial values and setup USD Rate
  useEffect(() => {
    let initial = Number(query.get("initial"))
    let monthly = Number(query.get("monthly"))

    setSubscription(monthly)
    let currency = query.get("usd")
    currency = JSON.parse(currency)
    getExchangeReate(initial, currency)
    getAvailableTokens()
  }, [])

  const getAvailableTokens = async () => {
    const availableTokens = await getAvailableCountByBuilding(user.token)
    setAvailableTokens(availableTokens)
  }

  // Get building Values and show open orders
  useEffect(() => {
    const getAllBuildings = async () => {
      const buildingsValue = await getPeriodValues(user.token, "2024-12-30") //The last month
      let orderPrice = 0

      const buildingWithTokens = buildingsValue.filter(
        (building) => building.building_name !== "HERNAN"
      )

      const buildingsByPrice = buildingWithTokens.sort(
        (a, b) => a.value_a - b.value_a
      )

      const buildingList = buildingsByPrice.map((building) => {
        return {
          name: building.building_name,
          valueUsd: Number(Number(building.value_a / USDRate).toFixed(0)),
          valueMxn: Number(building.value_a),
          quantity: 0,
          building_phase_id: building.building_phase_id
        }
      })

      //Show Open orders or fill with simulation
      if (!initialPayment) {
        //Show Open orders
        buildingList.forEach((building, index) => {
          if (cart[building.building_phase_id]?.quantity) {
            buildingList[index].quantity =
              cart[building.building_phase_id].quantity
            orderPrice +=
              building.valueMxn * cart[building.building_phase_id]?.quantity
          }
        })
      } else {
        // fill with simulation
        while (
          Math.abs(initialPayment - orderPrice) >= buildingList[0].valueMxn
        ) {
          buildingList.forEach((building, index) => {
            if (Math.abs(initialPayment - orderPrice) >= building.valueMxn) {
              buildingList[index].quantity += 1
              orderPrice += building.valueMxn
            }
          })
        }
      }

      setBuildings(buildingList)
      setNewPrice(orderPrice)
    }

    getAllBuildings()
  }, [initialPayment])

  const updateBuildings = (name, quantity) => {
    const newItems = [...buildings]
    const index = newItems.findIndex((item) => item.name === name)

    if (index !== -1) {
      newItems[index] = { ...newItems[index], quantity: quantity }
      setBuildings(newItems)
    }
  }

  // Update Redux Satate
  const setUserOrdersInCart = (orders) => {
    const newCart = {}
    orders.forEach((buildingOrder) => {
      newCart[buildingOrder.building_phase_id] = {
        quantity: buildingOrder.quantity,
        name: buildingOrder.name,
        value: buildingOrder.valueMxn
      }
    })
    setAddToCart(dispatch, newCart)
  }

  // Create and send order
  const createOrder = async () => {
    setShowLoader(true)
    const tokenList = buildings.filter((building) => building.quantity)
    setUserOrdersInCart(tokenList)
    const tokenOrders = Object.keys(cart).map((phaseId) => {
      return {
        investmentAmount: cart[phaseId].quantity * cart[phaseId].value,
        building: phaseId
      }
    })

    const result = await newOrder({
      userId: user.id,
      type: "catalog",
      orders: tokenOrders
    })
    history.push(
      `/resumen?initial=${newPrice}&monthly=${subscription}&hasFractions=true`
    )
  }

  return (
    <Layout dashboard lightLogo>
      <Container>
        {showLoader ? (
          <Loader />
        ) : (
          <>
            <h2>
              Selecciona tus m<sup>2</sup>
            </h2>

            <div className="container-buildings">
              {buildings &&
                buildings.map((building) => (
                  <Building
                    key={building.name}
                    building={building}
                    userBalance={newPrice}
                    updateNewPrice={setNewPrice}
                    availableTokens={
                      availableTokens[building.building_phase_id]
                    }
                  />
                ))}
            </div>

            <div className="container-results">
              <div className="container-price">
                <p>Monto total de compra:</p>
                <div className="container-currencies">
                  <span>{currency(newPrice, { precision: 0 }).format()}</span>
                  <span className="usd">
                    {currency(newPrice / USDRate, {
                      precision: 0
                    }).format()}
                    USD
                  </span>
                </div>
              </div>
              {user.report.userBalance > 0 && (
                <>
                  <div className="border">
                    <p>Anticipos disponibles:</p>
                    <div className="container-currencies">
                      <span>
                        {" "}
                        -{" "}
                        {currency(user.report?.userBalance, {
                          precision: 0
                        }).format()}
                      </span>
                      <span className="usd">
                        (
                        {currency(user.report?.userBalance / USDRate, {
                          precision: 0
                        }).format()}
                        ) USD
                      </span>
                    </div>
                  </div>
                  <div>
                    <p>Total a pagar:</p>
                    <div className="container-currencies">
                      <span>
                        {user.report.userBalance > newPrice
                          ? 10
                          : currency(newPrice - user.report?.userBalance, {
                              precision: 0
                            }).format()}
                      </span>
                      <span className="usd">
                        {user.report.userBalance > newPrice ? (
                          <>
                            (
                            {currency(10 / USDRate, {
                              precision: 2
                            }).format()}
                            ) USD
                          </>
                        ) : (
                          <>
                            {currency(
                              (newPrice - user.report?.userBalance) / USDRate,
                              {
                                precision: 0
                              }
                            ).format()}
                            USD
                          </>
                        )}
                      </span>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="container-button">
              <button className="continue-button" onClick={() => createOrder()}>
                Continuar
              </button>
            </div>

            <p className="description">
              Tu compra inicial automaticamente se distribuye entre las m
              <sup>2</sup> de los edificios disponibles. Puedes ajustar las
              cantidades libremente. EI monto de tu compra inicial se ajusto al
              precio y cantidad de las m<sup>2</sup> seleccionadas.
            </p>
          </>
        )}
      </Container>
    </Layout>
  )
}

const Container = styled.section`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;

  h2 {
    font-size: 2rem;
    font-family: "calibre";
    font-weight: 500;
    text-align: center;
  }

  .container-balance {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      margin: 0;
      color: #aaa;
      font-size: 1.3rem;
    }

    span {
      font-size: 1.8rem;
      font-weight: 500;
      color: #38493c;
    }
  }

  .container-buildings {
    display: flex;
    flex-direction: column;
  }

  .container-results {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    gap: 1rem;

    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      /* width: 100%; */
    }

    .container-currencies {
      display: flex;
      align-items: end;
      flex-direction: column;
    }

    .border {
      padding-bottom: 10px;
      border-bottom: 2px dashed #aaa;
    }

    p {
      margin: 0;
      font-weight: 600;
      font-size: 1.3rem;
    }

    span {
      font-size: 2rem;
      color: #f9a14d;
      font-weight: 500;
    }

    .usd {
      font-size: 1rem;
      color: #aaa;
      margin-left: 10px;
    }
  }

  .error-balance {
    margin: 0;
    color: red;
  }

  .description {
    color: #aaa;
    margin: 0;
    font-size: 1.2rem;
  }

  .container-button {
    display: flex;
    align-items: center;
  }

  .continue-button {
    width: min-content;
    margin: 0 auto;
    background-color: #fecfa4;
    padding: 15px 28px;
    font-weight: bold;
    border-radius: 30px;
    font-size: 1rem;
    font-family: "calibre";
    border: none;
    color: black;
    cursor: pointer;
  }

  .continue-button:hover {
    background-color: #f9c27a;
  }

  .continue-button:disabled {
    background-color: #aaa;
    cursor: not-allowed;
  }

  .warning {
    margin: 0;
    color: #aaa;
    font-size: 14px;
  }

  @media (min-width: 1023px) {
    max-width: 600px;
    margin: 0 auto;
  }
`
