import React from "react"
import styled from "styled-components"
import { Link } from "react-router-dom"
import { useSelector } from "react-redux"
// assets
import UrvitaLogo from "../../../assets/logos/logo-urvita-gray.png"
import InstagramLogoSVG from "../../../assets/logos/instagram.js"
import { colors } from "../constants"

const FooterStyled = styled.footer`
  align-items: center;
  background: ${colors.secondaryColor};
  color: ${colors.darkText};
  display: grid;
  grid-template-columns: ${(props) => (props.userReport ? "1fr" : "1fr 2fr")};
  padding: 60px 120px;
  position: absolute;
  left: 0;
  right: 0;

  @media print {
    display: none;
  }

  .grid-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    align-items: center;
  }

  h3,
  ul,
  p {
    margin: 0;
    padding: 0;
    list-style: none;
    font-weight: 500;
  }

  a {
    color: ${colors.darkText};
    font-weight: 500;
  }

  .urvita-logo {
    width: 150px;
  }

  .social-item {
    color: ${colors.darkText};
    text-decoration: none;
    margin: 20px 0;
  }

  .social-logo {
    vertical-align: middle;
    width: 18px;
  }

  .social-text {
    margin: 0 0 0 5px;
  }

  .footer-cta {
    color: ${colors.darkText};
    font-weight: 500;
    text-decoration: underline;
  }

  @media (max-width: 968px) {
    grid-template-columns: 1fr;
    grid-gap: 2rem;
    grid-column-gap: 10px;
    padding: 30px;

    .footer-rights {
      grid-column: 1/2;
      grid-row: 2/3;
      text-align: left;
    }

    .footer-text {
      grid-column: 2/3;
    }
  }
`

const Footer = () => {
  const userReport = useSelector((state) => state.user.report)
  return (
    <FooterStyled userReport={userReport}>
      <section className="grid-section">
        <img className="urvita-logo" src={UrvitaLogo} alt="logo de urvita" />
        <article>
          <h3>Contáctanos</h3>
          <ul>
            <li>‍info@urvita.com</li>
            <li>‍+(52) 814 160 54 41</li>
            <li>
              <a
                className="social-item"
                href="https://www.instagram.com/urvita"
                target="_blank"
                rel="noreferrer noopener"
              >
                <InstagramLogoSVG
                  className="social-logo"
                  color={colors.darkText}
                />
                <span className="social-text">Instagram</span>
              </a>
            </li>
          </ul>
        </article>
        <article>
          <p> Derechos Reservados.</p>
          <p>Urvita SAPI de CV @2025</p>
        </article>
        {userReport ? (
          <Link to="/terminos">Términos y condiciones</Link>
        ) : (
          <Link to="/terminos-y-condiciones" target="_blank">
            Términos y condiciones
          </Link>
        )}
        {/* <Link to="/terminos-y-condiciones" target="_blank">Términos y condiciones</Link> */}
        <p>
          Urvita no es una entidad financiera ni proporciona servicios
          financieros, de banca ni servicios análogos a sus usuarios
          registrados. EI contenido de esta página web no debe ser considerado
          de ninguna forma como recomendaciones, invitaciones o solicitudes de
          comprar o vender activos financieros o de participar en actividades de
          trading, estrategias de inversión o cualquier otra.
        </p>
      </section>
      {!userReport && (
        <section className="footer-section">
          <p>
            El propósito de esta página web es proporcionar información general
            de las actividades de Urvita para los usuarios registrados en su
            plataforma privada. Las actividades que realiza Urvita son
            exclusivamente privadas, esto quiere decir que su negocio se
            restringe a un número limitado de participantes que son sumados al
            proyecto de forma particular y tras cumplir con determinados
            procesos de autorización.
          </p>
          <br />
          <p>
            Urvita no es una entidad financiera ni proporciona servicios
            financieros, de banca ni servicios análogos a sus usuarios
            registrados. El contenido de esta página web no debe ser considerado
            de ninguna forma como recomendaciones, invitaciones o solicitudes de
            comprar o vender activos financieros o de participar en actividades
            de trading, estrategias de inversión o cualquier otra.
          </p>
          <br />
          <p>
            Al usar este sitio usted acepta nuestro -{" "}
            <Link to="/aviso-de-privacidad">Aviso de Privacidad.</Link>
          </p>
        </section>
      )}
    </FooterStyled>
  )
}

export default Footer
